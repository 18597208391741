import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const Terms = () => (
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "terms-conditions"}}) {
                  edges {
                     node {
                        TermsConditions {
                          welcomeToTeamlance {
                            welcomeTitle
                            welcomeDescription
                          }
                          topics {
                            title
                            link
                          }
                          headerOptions {
                            headerTitle
                            headerDescription
                          }
                        }
                      }
                  }
                }
            }
         `}
         render={data => {
            return(
              data && data.allWpPage &&
              data.allWpPage.edges &&
              data.allWpPage.edges[0] &&
              data.allWpPage.edges[0].node &&
              data.allWpPage.edges[0].node.TermsConditions &&
             <section className="share-sec">
               <div className="share-top">
                  <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"></path>
                  </svg>
               </div>
               <div className="container my-5 ">
                  <div className="row terms-wrapper">
                     <div className="col-md-4 pc-left">
                        <div className="side-pc">
                           <h3>Topics</h3>
                           <div className="desktop-hidden pc-menu">
                              <select className="form-select" aria-label="Default select example">
                                 {data.allWpPage.edges[0].node.TermsConditions.topics
                                  && data.allWpPage.edges[0].node.TermsConditions.topics.length>0
                                  && data.allWpPage.edges[0].node.TermsConditions.topics.map((data,key)=>{
                                     return(
                                       <option key={key} value={key} selected={key===0?"selected":""}>{data.title}</option>
                                     )
                                  })

                                 }
                              </select>
                              <span>
                                 <svg className="sl-arow" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.5625 0.875L5.5 4.9375L1.4375 0.875" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 </svg>
                              </span>
                           </div>
                           <ul className="mobile-hidden">
                              {data.allWpPage.edges[0].node.TermsConditions.topics
                                  && data.allWpPage.edges[0].node.TermsConditions.topics.length>0
                                  && data.allWpPage.edges[0].node.TermsConditions.topics.map((data,key)=>{
                                     return(
                                       <li key={key}><a href={data.link}>{data.title}</a></li>
                                     )
                                  })
                                 }
                           </ul>
                        </div>
                     </div>
                     <div className="col-md-8 pc-right">
                        <div className="privacy-content">
                           <div className="privacy-header mb-5">
                              <h1>Welcome to TeamLance!</h1>
                           </div>
                           <div className=" pc-inner pt-4">
                              <h4>Welcome to fancommunity!</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultricies consequat. Velit mattis magna tortor risus, et sit bibendum. Lorem sit in mauris cras id porta.
                                 <br /><br />
                                 At sed rhoncus semper purus amet curabitur sodales. Egestas pellentesque odio etiam cursus odio hendrerit mauris. Aliquet ullamcorper tempor neque, sed commodo. Turpis lectus eleifend id porttitor.
                              </p>
                           </div>
                           <hr />
                           <div className="pc-inner">
                              <h4>Your use of website</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultricies consequat. Velit mattis magna tortor risus, et sit bibendum. Lorem sit in mauris cras id porta.
                                 <br /><br />
                                 At sed rhoncus semper purus amet curabitur sodales. Egestas pellentesque odio etiam cursus odio hendrerit mauris. Aliquet ullamcorper tempor neque, sed commodo. Turpis lectus eleifend id porttitor.
                              </p>
                           </div>
                           <hr />
                           <div className=" pc-inner">
                              <h4>Ownership of contnet</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultricies consequat. Velit mattis magna tortor risus, et sit bibendum. Lorem sit in mauris cras id porta.
                                 <br /><br />
                                 At sed rhoncus semper purus amet curabitur sodales. Egestas pellentesque odio etiam cursus odio hendrerit mauris. Aliquet ullamcorper tempor neque, sed commodo. Turpis lectus eleifend id porttitor.
                              </p>
                              <h4 className="mt-4">Lorem Ipsum</h4>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultricies consequat. Velit mattis magna tortor risus, et sit bibendum. Lorem sit in mauris cras id porta</p>
                           </div>
                           <hr />
                           <div className=" pc-inner">
                              <h4>Trademarks</h4>
                              <p><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultr icies consequat. Velit mattis magna tortor risus, et sit bibendum.</b></p>
                              <br/> 
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh molestie sed nibh congue scelerisque. Turpis sed ut id habitant faucibus magna dolor fringilla. Sed eget amet nisl ac lorem in amet, ultricies consequat. Velit mattis magna tortor risus, et sit bibendum. Lorem sit in mauris cras id porta.
                                 <br /><br />
                                 At sed rhoncus semper purus amet curabitur sodales. Egestas pellentesque odio etiam cursus odio hendrerit mauris. Aliquet ullamcorper tempor neque, sed commodo. Turpis lectus eleifend id porttitor.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            )
         }}
      />
  
)

export default Terms;