import React from 'react';
import singlecbg from "../../assets/images/single-c-bg.png"
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
    <StaticQuery
         query={graphql`
            query {
              allWpPage(filter: {slug: {eq: "terms-conditions"}}) {
                edges {
                   node {
                      TermsConditions {
                        welcomeToTeamlance {
                          welcomeTitle
                          welcomeDescription
                        }
                        topics {
                          title
                          link
                        }
                        headerOptions {
                          headerTitle
                          headerDescription
                        }
                      }
                    }
                }
              }
            }
         `}
         render={data => {
           console.log("Data", data);
             return(
                <section className="quote-bn">
                    <div className="bn-bg quote-bg">
                        <img src={singlecbg} alt="case study" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12  d-flex flex-column sectiontop-pd  ">
                                <div className=" quote-banner text-center case-heading my-auto ml-0 ">
                                    <h3 className="">Terms & Conditions</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac purus enim nisl risus. <br />Hendrerit ut purus aliquet feugiat a morbi vitae sollicitudin.
                                    </p>
                                </div>
                            </div>
                        </div> 
                    </div>
                 </section>
             )
         }}
    />
    
)

export default Banner;