import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo";
import Banner from "../components/terms/banner";
import TermsandCondition from "../components/terms/terms";

const Terms = (props) => (
     <Layout {...props}>
         <SEO title="Terms"/>
         <div class="col-12 wrapper main-bggrey p-0">
              <div class="col-12 inner-page-content home-inner px-0">
                   <Banner/>
              </div>
         </div>
         <TermsandCondition/>
     </Layout>
)

export default Terms;